import React from 'react';
import loadable from '@loadable/component';

const CategoryContainerV1 = loadable(() => import('./CategoryContainerV1'));

const CategoryContainer = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case 'version1':
      return <CategoryContainerV1 {...props} />;
  }
};

export default CategoryContainer;
